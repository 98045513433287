<script setup lang="ts">
import { z } from "zod";
import { processError, requiredError, successToast } from "~/lib/utils/errors";

const omniaSdk = useOmniaSdk();

const forgotPasswordOpened = defineModel<boolean>("opened");
const emits = defineEmits(["success"]);
const loading = ref<boolean>(false);

const forgotPasswordSchema = z.object({
  email: z.string(requiredError).trim().email("Email non valida").describe("Email"),
});
const forgotPasswordForm = useForm({ validationSchema: toTypedSchema(forgotPasswordSchema) });

async function forgotPassword(values: Record<string, any>) {
  loading.value = true;

  try {
    await omniaSdk.auth.user.forgotPassword({ email: values["email"] });

    successToast("Ti abbiamo inviato un'email con il link per reimpostare la password.");

    emits("success");
  } catch (error) {
    processError(error);
  }

  loading.value = false;
}
</script>

<template>
  <Dialog v-model:open="forgotPasswordOpened">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Recupera la password</DialogTitle>
        <DialogDescription>Inserisci la tua email per reimpostare la password</DialogDescription>
      </DialogHeader>

      <AutoForm @submit="forgotPassword" :form="forgotPasswordForm" :schema="forgotPasswordSchema" class="content-card-grid grid gap-4">
        <DialogFooter>
          <DialogClose>
            <Button variant="ghost" type="reset">Annulla</Button>
          </DialogClose>
          <Button type="submit" :disabled="loading" :class="{ 'animate-pulse': loading }">Recupera</Button>
        </DialogFooter>
      </AutoForm>
    </DialogContent>
  </Dialog>
</template>
