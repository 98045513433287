<script setup lang="ts">
import { z } from "zod";
import { processError, requiredError, successToast } from "~/lib/utils/errors";
import ForgotPasswordDialog from "./ForgotPasswordDialog.vue";

const omniaSdk = useOmniaSdk();
const loginOpened = defineModel<boolean>("opened");
const loading = ref<boolean>(false);

const forgotPasswordOpened = ref(false);

const loginFormSchema = z.object({
  email: z.string(requiredError).trim().email("Email non valida").describe("Email"),
  password: z.string(requiredError).describe("Password"),
});
const loginForm = useForm({ validationSchema: toTypedSchema(loginFormSchema) });

async function login(values: Record<string, any>) {
  loading.value = true;

  try {
    const accessToken = await omniaSdk.auth.user.loginWithCredentials(values["email"], values["password"]);

    if (accessToken) {
      omniaSdk.sdkConfig.authenticationStrategy.setAccessToken(accessToken);

      loginOpened.value = false;
      location.reload();
    }
  } catch (error) {
    processError(error);
  }

  loading.value = false;
}
</script>

<template>
  <Dialog v-model:open="loginOpened">
    <DialogScrollContent class="max-w-2xl">
      <DialogHeader>
        <DialogTitle class="flex items-center gap-3">
          <Icon name="carbon:user-avatar" class="w-10 h-10" />
          <span class="text-2xl">Accedi</span>
        </DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <AutoForm @submit="login" :form="loginForm" :schema="loginFormSchema" :field-config="{ password: { inputProps: { type: 'password' } } }" class="content-card-grid grid gap-4">
        <DialogFooter>
          <div class="mt-4 w-full flex flex-col gap-4 items-center justify-center">
            <Button class="w-full" type="submit" :disabled="loading" :class="{ 'animate-pulse': loading }">Accedi</Button>
            <Button type="button" variant="link" class="link" @click="forgotPasswordOpened = true">Hai dimenticato la password?</Button>
          </div>
        </DialogFooter>
      </AutoForm>
    </DialogScrollContent>
  </Dialog>

  <ForgotPasswordDialog v-model:opened="forgotPasswordOpened" @success="(loginOpened = false), (forgotPasswordOpened = false)" />
</template>
